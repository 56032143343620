import React from "react";
import { Accordion } from "react-bootstrap";
import "../faq/faq.css";

const FaqDDE = (faq_data) => ({ __html: JSON.stringify(faq_data) })

const FaqOpen = ({ faq, customClass }) => {
  const fcls = 'stadard-faq';
  const faqQuestion = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": []
};

  return (
    <>
      <h3 className="faq-title">Preguntas Frecuentes</h3>
      <Accordion defaultActiveKey="all" className={`faq-container ${customClass || fcls } open-all`}>
        {faq.list.length > 0 &&
          faq.list.map((element, key) => {
            faqQuestion.mainEntity.push({
                "@type": "Question",
                "name": element.question,
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": element.answer
                }
            })
            return (
                <Accordion.Item className={`q-${key}`} key={`${key}`}
                  style={key === 0 ? { borderTop: "none" } : {}}
                  eventKey="all"
                >
                      <Accordion.Header as="h4" aria-expanded={true}  className="faq-question" >
                        {element.question}
                      </Accordion.Header>
                      <Accordion.Body className="faq-answer">
                          <div dangerouslySetInnerHTML={ {__html: element.answer} } />
                      </Accordion.Body>
                </Accordion.Item>
            );
          })}
      </Accordion>
      <script type="application/ld+json" dangerouslySetInnerHTML={ FaqDDE(faqQuestion) }></script>
    </>
  );
};

export default FaqOpen;
